import illustation1 from "../assets/rafiki.png";
import illustration2 from "../assets/illustration2.png";
import illustration3 from "../assets/illustration3.png";
const OurFeature = [
  {
    image: illustation1,
    heading: "Understand Concepts your Way",
    para: "You are unique, so has to be your learning method and pace. With our mobile app, get the customized learning experience tailored just for you. Mastering concepts has never been more personalized and easy! ",
  },
  {
    image: illustration2,
    heading: "Clear your Doubts Instantly",
    para: "No more lingering questions! Instantly dissolve uncertainties with the app, offering real-time doubt resolution with an Ai Buddy which helps you keep your learning path smooth and clear.",
  },
  {
    image: illustration3,
    heading: "Have Fun with Learning",
    para: "Turn learning into a joyful adventure! Our app uses interactive quizzes, exam prep, and leaderboards to make every concept a fun discovery. Learn with smiles and fun!",
  },
];
export default OurFeature;
