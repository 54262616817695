export const data1 = {
  title: "License",
  description1:
    "Unless otherwise stated, Eduzy and/or its licensors own the intellectual property rights for all material on Eduzy. All intellectual property rights are reserved. You may access this from Eduzy for your personal use subject to restrictions set in these terms and conditions.",
  description2: [
    `Republish material from Eduzy`,
    `Sell, rent or sub-license material from Eduzy`,
    `Reproduce, duplicate or copy material from Eduzy`,
    `Redistribute content from Eduzy`,
  ],
  description3:`Parts of this App/website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Eduzy does not filter, edit, publish or review Comments before their presence on the website. Comments do not reflect the views and opinions of Eduzy, its agents and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Eduzy shall not be liable for the Comments or any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this App/website.`,
  description6:`Eduzy reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or cause a breach of these Terms and Conditions.
  `,
  description4:[
`    You are entitled to post the Comments on our App/website and have all necessary licenses and consents to do so;`,
`    The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;`,
`    The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful material which is an invasion of privacy`,
    `The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.`
      

],
description5:`You hereby grant Eduzy a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media`
};


export  const data2 = [
    {
        title: "1. Acceptance of Terms",
        description:[
`1.1. By accessing or using the Eduzy App/website, you agree to be bound by these Terms and any additional terms and conditions that may apply to specific features of the App/website, including without limitation the Privacy Policy.`,
`1.2. If you do not agree to these Terms or any additional terms and conditions, you may not access or use the Eduzy App/website`
        ]
    },

    {title:" 2. Access and Use of the App    ",
    description:[
        `2.1. You must be at least 10 years old or have the consent of a parent or guardian to use the App.
        `,
        `2.2. You agree to use the App/website only for lawful purposes and in accordance with these Terms and all applicable laws and regulations.
        `,
        `2.3. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device to prevent unauthorized access to your account.`,
        `2.4. You agree not to:
        `,

    ],
    description2:[
        ` a) Use the App/website in any way that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the App;
        `,
        ` b) Use the App/website for any illegal or unauthorized purpose or engage in, encourage, or promote any activity that violates these Terms or any applicable law or regulation;
        `,
        ` c) Use any robot, spider, crawler, scraper, or other automated means or interface not provided by us to access the App/website or to extract data;
        `,
        `d) Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the App, or which, as determined by us, may harm the Company or users of the App/website or expose them to liability;`,
        `   e) Attempt to bypass any measures we may use to prevent or restrict access to the App, or otherwise attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the App, the server on which the App/website is stored, or any server, computer, or database connected to the App;`,
        `   f) Access or use the App/website for any commercial purposes without our prior written consent.
        `,
    ]
},
{
    title:"3. Description of Service",
    description:[
        `3.1. The Eduzy App/website is an educational platform that provides users with access to a variety of educational content, including courses, tutorials, quizzes, and other learning materials.`,
        `3.2. The content available on the Eduzy App/website may be provided by Eduzy Inc., third-party content providers, or users of the App.
        `,
        `3.3. The Eduzy App/website may also include features such as user profiles, messaging functionality, and social networking features.
        `,
    ]
},
{
title:"4. User Accounts",
description:[
    `4.1. In order to access certain features of the Eduzy App, you may be required to create a user account.`,
    `4.2. You agree to provide accurate, current, and complete information when creating your user account, and to update your information as necessary to keep it accurate, current, and complete.
    `,
    `4.3. You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account or password.
    `,
    `4.4. You agree to notify Eduzy Inc. immediately of any unauthorized use of your account or any other breach of security.
    `,

]
},
{
    title:"5. Use of Content",
    description:[
        `5.1. All content available on the Eduzy App, including without limitation courses, tutorials, quizzes, and other learning materials, is owned by Eduzy Inc. or its licensors.
        `,
        `5.2. You may access and use the content available on the Eduzy App/website solely for your personal, non-commercial use.`,
        `5.3. You may not copy, reproduce, distribute, transmit, display, perform, or create derivative works of any content available on the Eduzy App/website without the prior written consent of Eduzy Inc. or the applicable content owner.
        `,
        `5.4. You may not use the Eduzy App/website or any content available on the Eduzy App/website for any unlawful purpose or in any way that violates these Terms or any applicable law or regulation.`,
    ]
},
{
    title:"6. User Content",
    description:[
        `6.1. The Eduzy App/website may allow users to submit, post, or transmit content, including without limitation comments, messages, and other materials ("User Content").`,
        `6.2. You retain ownership of any User Content that you submit, post, or transmit through the Eduzy App.
        `,
        `6.3. By submitting, posting, or transmitting User Content through the Eduzy App, you grant Eduzy Inc. a worldwide, non-exclusive, royalty-free, fully paid-up, perpetual, irrevocable, sublicensable, and transferable license to use, reproduce, distribute, modify, adapt, publicly perform, publicly display, and create derivative works of such User Content in any media now known or hereafter developed.
        `,
        `6.4. You represent and warrant that you have all rights necessary to grant the foregoing license to Eduzy Inc., and that the use of your User Content by Eduzy Inc. will not infringe or violate any third-party rights.`,

    ]
},
{
    title:"7. Intellectual Property",
    description:[
        `7.1. The Eduzy App/website and all content available on the Eduzy App, including without limitation text, graphics, logos, icons, images, audio clips, video clips, data compilations, and software, are the property of Eduzy Inc. or its licensors and are protected by copyright, trademark, and other intellectual property laws.
        `,
        `7.2. You may not use any trademarks, logos, or service marks displayed on the Eduzy App/website without the prior written consent of Eduzy Inc. or the applicable trademark owner`,
        `7.3. You may not remove, alter, or obscure any copyright notice, trademark notice, or other proprietary rights notice displayed on or in connection with the Eduzy App.`,
    ]
},

{
    title:"8. Privacy",
    description:[
        `8.1. Your privacy is important to us. Please refer to our Privacy Policy [link to Privacy Policy] for information about how we collect, use, and disclose your personal information.
        `
    ]
},

{
    title:"9. Termination",
    description:[
        `9.1. Eduzy Inc. may terminate or suspend your access to all or part of the Eduzy App/website at any time, with or without cause, with or without notice, effective immediately.
        `,
        `9.2. Upon termination or suspension of your access to the Eduzy App, your right to use the Eduzy App/website will immediately cease, and Eduzy Inc. may delete or disable your account and all related information and files associated with your account.
        `,
    ]
},

{
    title:"10. Cookies",
    description:[
        `The website/ app uses cookies to help personalize your online experience. By accessing Eduzy, you agree to use the required cookies.
        `,
        `A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.
        `,
        `We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You can accept or decline optional Cookies. Some required Cookies are necessary for the operation of our website/app. These cookies do not require your consent as they always work. Please keep in mind that by accepting required Cookies, you also accept third-party Cookies, which might be used via third-party provided services if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.
        `
    ]
}


]


export const data3 = 
    {
        title:"11. Hyperlinking to our Content:",
        description1:`The following organizations may link to our Website without prior written approval:
        `,
        description2:[
            `Government agencies; 	`,
            `Search engines`,
            `News organizations;`,
            `Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and 	`,
            `System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.`,
        ],
        description3:[`These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.`,
    `We may consider and approve other link requests from the following types of organizations:`,],
    description4:[`commonly-known consumer and/or business information sources;`,
`dot.com community sites;`,
`associations or other groups representing charities; `,
`online directory distributors; 	
`,
`internet portals;`,
`accounting, law, and consulting firms; and 	
`,
`educational institutions and trade associations. 	`
],
description5:[
    `We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Eduzy; and (d) the link is in the context of general resource information.
    `,
    `These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
    `,
    `If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an email to Eduzy at teameduzy@gmail.com. `
    ,
    `Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Please wait 2-3 weeks for a response`,
    `Approved organizations may hyperlink to our Website as follows:`,
],
description6:[
    `By use of our corporate name; or `,
    `By use of the uniform resource locator (URL) being linked to; or 	
    `,
    `Using any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site. 	`
],
description7:`No use of Eduzy's logo or other artwork will be allowed for linking absent a trademark license agreement.
`
    }

export const data4 = [
    {
        title:"12. Removal of links from our website:",
        description:[
            `If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.
            `,
            `We do not ensure that the information on this website is correct. We do not warrant its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.`
        ]
    },

    {
        title:"13. Content Liability:",
        description:[`We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are raised on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
        `]
    },
    {
        title:"14. Reservation of Rights:        ",
        description:[`We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
        `]
    },
    {
title:"15. iFrames:",
description:[`Without prior approval and written permission, you may not create frames around our Web pages that alter in any way the visual presentation or appearance of our Website.
`]
    },
    {
        title:`16. Disclaimer of Warranties`,
        description:[`16.1. THE EDUZY App/website IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        `,
    `16.2. EDUZY DOES NOT WARRANT THAT THE EDUZY App/website WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE EDUZY App/website OR THE SERVERS THAT MAKE THE EDUZY App/website AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.`]
    },
    {
title:`17. Limitation of Liability
`,
description:[
    `17.1. IN NO EVENT WILL ITONIC LABS PVT LTD/ EDUZY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE EDUZY APP/ WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF ITONIC LABS PVT LTD/EDUZY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    `,
    `17.2. ITONIC LABS PVT LTD/EDUZY’S TOTAL LIABILITY TO YOU FOR ALL CLAIMS ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE EDUZY App/website WILL NOT EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE EDUZY App/website DURING THE SIX-MONTH PERIOD IMMEDIATELY PRECEDING THE DATE OF THE CLAIM.
    `,

]
    },
    {
        title:`18. Governing Law
        `,
        description:[
            `18.1. These Terms and your use of the Eduzy App/website will be governed by and construed by the laws of Hyderabad, Telangana, without regard to its conflict of law principles.
            `,
        ]
    },
    {
        title:`19. Changes to Terms

        `,
        description:[`19.1.Itonic Labs Pvt Ltd. may revise and update these Terms from time to time at its sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Eduzy App/website thereafter.
        `]
    },
    
]

