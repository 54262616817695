import React, { useState } from "react";
import downArrow from "../assets/Group 35538.png";
import uparrow from "../assets/Vector 206.png";
import ConatactForm from "./ContactForm";

const FaqQues = (props) => {
  const [showForm, setForm] = useState(false);

  return (
    <div className={` faqques1  min-h-[100px] px-[5%] `}>
      {showForm === true ? (
        <ConatactForm showForm={showForm} setForm={setForm} />
      ) : null}
      <div className="flex flex-row justify-between space-x-[12px] pt-[30px] mb-[30px] sm:mb-0 align-top items-center ">
        <p className=" text-[1.1rem] md:text-[1.25rem] faqques2  ">
          {props.data.ques}
        </p>
        {props.isOpen === false ? (
          <img
            src={downArrow}
            className="w-[51px] h-[51px] hover:cursor-pointer"
            alt=""
            srcset=""
            onClick={() => {
              props.onToggle();
              setForm(false);
            }}
          />
        ) : (
          <div>
            <img
              src={uparrow}
              className="absolute z-40 ml-4 mt-5 hover:cursor-pointer "
              alt=""
              srcset=""
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              onClick={() => {
                props.onToggle();
                setForm(false);
              }}
            >
              <ellipse
                cx="25.2072"
                cy="25.3309"
                rx="25.2072"
                ry="25.3309"
                fill="#006B7F"
                fill-opacity="0.1"
              />
            </svg>
          </div>
        )}
      </div>

      {props.isOpen === true ? (
        <p className=" mb-4 mr-4 max-[7] lg:mr-[80px] md:faqques3  md:text-[1.2rem] text-justify">
          {props.data.ans}
          {props.index === 1 ? (
            <p>
              Sign up{" "}
              <span
                onClick={() => {
                  setForm(true);
                }}
                className="hover:cursor-pointer text-blue-800"
              >
                here
              </span>{" "}
              to get notified the minute we launch.
            </p>
          ) : null}
        </p>
      ) : null}
    </div>
  );
};

export default FaqQues;
