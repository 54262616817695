import React from 'react';

const SuccessPage = () => {
  return (
    <div className="max-w-lg mx-auto my-8 p-8 bg-white rounded shadow">
      <h1 className="text-3xl font-semibold mb-6">Account Successfully Deleted</h1>
      <p className="text-gray-700 mb-4">
        Your account has been successfully deleted. We're sorry to see you go!
      </p>
    </div>
  );
};

export default SuccessPage;
