export const data=[
{
    title :"Privacy Policy",
   description :[`We highly value the trust you place in us. To uphold this trust, Eduzy (operating under Itonic Labs Pvt. Ltd) adheres to ethical standards when collecting, using, and securing any information you provide. This privacy policy governs your usage of the 'Eduzy' mobile application and the website www.eduzy.com, managed by the Company. Before using the Application and the website, please read this privacy policy ('Policy') and the Terms of Use ('ToU') available on the Application and the Website carefully. 
   ` ], 
},
{
    title: "Consent",
description:[`Your utilization of the Website or the Application indicates your acceptance of this Policy and your agreement to be legally bound by its terms. `]    ,
},

{
  title: "User Provided Information",
  description: [
    `The Application/Website obtains the information you provide when you download and register for the Application. When you register with us, you generally provide (a) your name, age, email address, location, phone number, password, guardian’s details and your ward's educational details; (b) transaction-related information, such as when you make purchases, respond to any offers, or download or use applications from us; (c) information you provide us when you contact us for help; (d) information you enter into our system when using the Application/Services/products, such as while asking doubts, participating in discussions and taking tests. `,
      `The information gathered from users may fall into the categories of "Personal Information," "Sensitive Personal Information," and "Associated Information." These terms, individually defined under the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 (referred to as the "Data Protection Rules"), collectively constitute 'Information' within the scope of this Policy.`,
      `We may utilize the Information to occasionally contact you, delivering Services, essential information, required notices, and marketing promotions. When we require additional information for personal identification (personal information) or to establish contact, we will request your explicit consent.`
  ]
},
{
  title: "Use of your Personal Information",
  description: [
`The Information we gather serves various purposes, including trend analysis, research, administration of the Application/Services and products, understanding individual learning patterns and user navigation, and collecting demographic information and overall user behaviour.`,
`Both aggregated and individual data, anonymized and non-anonymized, may be periodically shared with external service providers to enhance the Application, products, and Services. Your information will only be shared with third parties as specified in this Policy.
`,
'Individual data and behavioural patterns, when combined with personal information, may be utilized to deliver personalized content and enhance your learning objectives. Certain third-party services assist in analyzing data and information for personalization, and insights, and improving your overall experience or providing you with additional value-added applications, products, information, and services. It\'s important to note that these third-party companies do not have independent rights to share this information.',
'We do not sell, trade or share your Information to any third party (except subsidiaries/affiliates of the Company for related offerings) unless we have been expressly authorised by you either in writing or electronically to do so',
`We refrain from selling, trading, or disclosing your Information to any third party (excluding subsidiaries/affiliates of the Company for related offerings) unless we have received explicit authorization from you, either in written or electronic form.`,
`Occasionally, we may share aggregate statistics concerning our customers, traffic patterns, and related site information with reputable third parties. However, any disclosed information will be in an aggregate form, devoid of Personally Identifiable Information. Eduzy may periodically send emails, and messages, or contact you to communicate essential information about our Services, products, and benefits related to your chosen Services/products.
`,
],
description2: [

  `As required by law, such as to comply with a subpoena or similar legal process.`,
  `To enforce applicable Terms of Use, including the investigation of potential violations.`,
  `When we have a good faith belief that disclosure is necessary to protect our rights, your safety or the safety of others, investigate fraud, address security or technical issues, or respond to a government request.`,
  `To our trusted service providers working on our behalf, who do not independently use the disclosed information and have agreed to adhere to the rules outlined in this Policy.
  `,
  `To protect against imminent harm to the rights, property, or safety of the Application/Website/ Itonic Labs Private Limited, its users, or the public, as required or permitted by law.
  `,
  `With third-party service providers for personalizing the Application/Website/Services/products to enhance user experience and conduct behavioural analysis.`,
  `Any Information containing personal data related to minors provided by you is considered to be given with the consent of the minor's legal guardian. Such consent is deemed to be provided through your registration with us.`,

]
},





   
{
    title:  "Access to your Personal Information ",
    description:[`We offer mechanisms to help you verify and update your Personal Information to ensure its accuracy and currency. If you have completed a user profile, we will provide a clear method for you to access and modify your profile within our Application/website. Additionally, we implement sensible security measures to safeguard your password and prevent its exposure or disclosure to unauthorized individuals.
    `,]
},
   



  {  title:"Cookies",
  description: [`We send cookies (small files containing a string of characters) to your computer, uniquely identifying your browser. Cookies track your preferences, help you log in faster, and are aggregated to determine user trends. This data is used to improve our offerings, such as providing more content in areas of greater interest to a majority of users. Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. Some of our features and services may not function properly if your cookies are disabled.
  `,]},

  {    title :"Links",
  description:[`We might display links in a format that allows us to monitor whether they have been clicked on. This data is utilized to enhance our personalized content. Clicking on these links may redirect you to websites beyond our domain. Please note that we are not accountable for the privacy practices of other websites. We urge our users to exercise caution and read the privacy statements of every website that gathers personally identifiable information when leaving our site. It is important to emphasize that this Privacy Policy exclusively pertains to information collected by our Website.
  `,]
},



 {
    title:"Alerts",
    description:[`We might notify you via email or phone (including SMS, calls, or WhatsApp) to update you on new services provided by the Company and its subsidiaries/affiliates. Additionally, we may share other information that we believe could be beneficial to you through the Company or its subsidiaries/affiliates.
    `,]
 },



{   title: "Security",
description:[`We prioritize ensuring the confidentiality of your information and have implemented electronic and procedural measures to safeguard the data we process and retain. For instance, access to this information is restricted to authorized employees with a legitimate need to know to operate, develop, or enhancing our Application/Services/Products/Website. It is important to note that, despite our efforts to establish reasonable security measures for the information we handle, no security system can eliminate the possibility of all potential security breaches.`,]},

{   title: "How Long Do We Retain User Data? ",
description:[`CAt present, we intend to preserve user data for the duration of an active account and at least three years beyond that. This approach may be subject to modification based on legal and business necessities. For instance, we might extend the duration of data retention for specific information to adhere to legal requirements or voluntary codes of conduct. Conversely, unless prohibited, we may reduce the retention period for certain data types to optimize storage space.
`],
},

 { title:  "Log information",
 description:[`Upon visiting our Website/Application, our servers automatically capture details sent by your browser, a standard practice for web interactions. These server logs may encompass data like your web request, internet protocol (IP) address, browser category, language, the timestamp of your request, and possibly one or more cookies that can distinctly identify your browser.
 `,]},

   {
    title: "User communications",
description:[`WWhen you send an email or any other form of communication to us, we may keep such communications to facilitate the handling of your queries, address your requests, and enhance the quality of our Services.
`]
   },
{title:"Changes to this Statement",
description:[`As the Company progresses, our privacy policy must adapt to address emerging situations. It is recommended that you regularly review this Policy for any updates, as your ongoing use is considered an endorsement of all modifications.
`]},

{title:"Your Consent",
description:[`We firmly believe that all users of our Application/Services/Products/Website should be able to provide informed consent before sharing any required information for utilizing the Application/Services/Products/Website. By registering with us, you explicitly agree to our collection, processing, storage, disclosure, and handling of your information as outlined in this Policy, both presently and as modified by us in the future. The processing of your information encompasses various activities such as collecting, storing, deleting, using, combining, sharing, transferring, and disclosing, all of which will occur in India. In the case of residing outside India, your information will be transferred, processed, and stored in compliance with the relevant data protection laws of India.`,
]}
 


    ];