import mobile from "../assets/mobile.png";
import Downloadbutton from "./Downloadbutton";
import landingPageVideo from "../assets/landingPageVideo.mp4";
const Heropage = () => {
  return (
    <div className="flex flex-row flex-wrap-reverse justify-center  lg:justify-normal lg:pl-[5rem] w-[100%]  heropage1  min-[1900px]:pl-[10vw] ">
      <div className="lg:w-7/12 pb-[15%] pt-[8rem] px-[15px] lg:px-0 ">
        <div className="pb-[4rem] flex flex-row ml-20  "></div>

        <p className="text-[2rem] md:text-[3.5rem] leading-[120%] font-serif">
          {/* Eduzy Combines Learning & AI to Improve Learning */} Elevate your
          exam readiness with our app
        </p>
        <p className="text-[1.3rem] md:text-[1.625rem] mt-[0.5rem]  ">
          Rich resources, interactive AI Bot and targeted practice for optimal
          exam success!{" "}
        </p>
        <div className="pt-[120px] px-[20vw] lg:pl-0 ">
        <a href="https://play.google.com/store/apps/details?id=com.classganana.BBJr&pcampaignid=web_share" target="_blank">
        <button className="px-3 py-1.5 flex gap-2 items-center rounded-xl   bg-[#2947D4] text-white hover:bg-[#293b8a]">
  <div className="w-10">
  <svg viewBox="30 336.7 120.9 129.2" width="38">
                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z">
                    </path>
                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z">
                    </path>
                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z">
                    </path>
                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z">
                    </path>
                </svg>
  </div>
  <div className="">
    <div className="text-sm font-extrabold ">Download on the</div>
    <div className="text-2xl ">Google Play</div>
  </div>
</button>
</a>
        </div>
      </div>
      <div className="lg:w-5/12  md:mx-0 ">
        <img
          className=" h-[660px]  lg:right-[11.5%]  top-[113.5px] relative lg:absolute z-20  "
          src={mobile}
          alt={`mobile blank screen`}
        />
        <video
          autoPlay
          muted
          loop
          disablePictureInPicture
          className="  absolute  top-[150px]   lg:right-[13.6%] ml-[6.5%]  lg:ml-0   z-30   h-[585px] 2xl:-mr-[5px] min-[1900px]:-mr-[18px]  rounded-[32px] heropage3 "
          src={landingPageVideo}
        />
      </div>
    </div>
  );
};

export default Heropage;
