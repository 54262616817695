import React from "react";

const Downloadbutton = () => {
  return (
    <div className="flex flex-row space-x-5">
    
    <a href="https://play.google.com/store/apps/details?id=com.classganana.BBJr&pcampaignid=web_share" target="_blank"> <button className="bg-[#DEDEDE] rounded-[8px] w-[195px] h-[46px]">
        Download now
      </button>  </a>
    </div>
  );
};

export default Downloadbutton;
