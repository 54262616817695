import React, { useState } from 'react';
import httpService from '../Services/httpServices';

const DeleteAccount = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState("Your account has been successfully deleted!");

    const handleDeleteAccount = async () => {
        try {
            const response = await httpService.delete(`/auth/${phoneNumber}`);
            if(response?.data?.deletedCount > 0){
                setToastText("Your account has been successfully deleted!");
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
                window.location.href = '/success';
            } else {
                setToastText("Account not found!");
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="w-full mx-auto my-4 p-8 bg-white rounded shadow">
            <h1 className="text-3xl font-semibold mb-6">Delete Account</h1>
            <p className="text-gray-700 mb-4">We're sorry to see you go! If you wish to delete your account, please read the following information carefully:</p>
            <div className="mb-6">
                <strong className="block text-gray-800 mb-2">Important Note:</strong>
                <ul className="list-disc list-inside text-gray-700">
                    <li>Once your account is deleted, all of your data will be permanently removed from our system. This includes your profile information, preferences, saved items, and any other data associated with your account.</li>
                    <li>You will no longer have access to your account, and any associated benefits, such as subscription services or rewards, will be forfeited.</li>
                    <li>If you have any active subscriptions, they will be canceled, and any remaining balance or benefits will be lost.</li>
                    <li>This action cannot be undone. Once your account is deleted, it cannot be recovered.</li>
                </ul>
            </div>
            <div className="mb-6">
                <strong className="block text-gray-800 mb-2">Before You Proceed:</strong>
                <ul className="list-disc list-inside text-gray-700">
                    <li>Consider alternative options: If you're experiencing issues with our service or have concerns about your account, please reach out to our support team. We're here to help and may be able to address your concerns without deleting your account.</li>
                    <li>Review your data: Take a moment to review any important information or data associated with your account. Once your account is deleted, this data cannot be recovered.</li>
                </ul>
            </div>
            <div className="mb-6">
                <strong className="block text-gray-800 mb-2">How to Delete Your Account:</strong>
                <ol className="list-decimal list-inside text-gray-700">
                    <li>Log in to your account: Visit our website or open our app and log in using your username and password.</li>
                    <li>Access account settings: Navigate to your account settings or profile settings. Look for an option that allows you to manage your account or delete your account.</li>
                    <li>Initiate account deletion: Once you've found the option to delete your account, follow the prompts to confirm your decision. You may be asked to provide a reason for deleting your account, but this is optional.</li>
                    <li>Confirmation: After confirming your decision, your account deletion request will be processed. You may receive a confirmation email or notification once the process is complete.</li>
                </ol>
            </div>
            <div className="mb-6">
                <label htmlFor="phoneNumber" className="block text-gray-800 mb-2">Phone Number:</label>
                <input
                    type="text"
                    id="phoneNumber"
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </div>
            <div className="mb-6">
                <strong className="block text-gray-800 mb-2">Need Help?</strong>
                <p className="text-gray-700">If you have any questions or need assistance with deleting your account, please contact our support team at <a href="mailto:support@example.com" className="text-blue-500">support@itoniclabs.in</a>. We're here to assist you and ensure a smooth transition.</p>
            </div>
            <button onClick={handleDeleteAccount} className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600">Delete My Account</button>
            {showToast && (
                <div className="fixed bottom-0 right-0 left-0 mx-auto max-w-screen-sm m-4 p-4 bg-green-500 text-white rounded">
                    {toastText}
                </div>
            )}
        </div>
    );
};

export default DeleteAccount;
