import React, { useState } from "react";
import FaqQuesData from "../data/FaqQuesData";
import FaqQues from "./FaqQues";

const Faq = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

  const handleQuestionToggle = (index) => {
    setOpenQuestionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="px-[5%] md:px-[20%] pt-[49px] ">
      <p className="faq1 text-center mb-[33px] ">FAQs</p>
      <div className="flex flex-col space-y-[30px] mt-10 ">
        {FaqQuesData.map((data, index) => {
          return (
            <FaqQues
              data={data}
              index={index}
              isOpen={index === openQuestionIndex}
              onToggle={() => handleQuestionToggle(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
