// httpService.js

import axios from 'axios';

const httpService = axios.create({
  baseURL: 'http://localhost:6969', // Replace with your API base URL
  // baseURL: 'http://3.108.255.68:3000', // Replace with your API base URL
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export default httpService;