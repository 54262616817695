import React from "react";

const PriceCard = ({ data }) => {
  return (
    <div
      className={`pricingCard1 w-[280px] min-[355px]:w-[350px] md:w-[394px] flex flex-col justify-between ${
        data.planName === "Quarterly Excellence" ? "bg-[#2947D4]" : "bg-[#fff]"
      }`}
    >
      <div>
        <div className="flex flex-row space-x-2">
          {/* <div className="w-[72px] h-[72px] bg-slate-200"></div> */}
          <div className="flex flex-row">
            <div>
              <p
                className={`pricingCard2  ${
                  data.planName === "Quarterly Excellence"
                    ? "text-[#EFF0F6]"
                    : "text-[#6F6C90]"
                }`}
              >
                {data.billingType}
              </p>
              <p
                className={`pricingCard3 pt-2 ${
                  data.planName === "Quarterly Excellence"
                    ? "text-[#FFF]"
                    : "text-[#170F49]"
                }`}
              >
                {data.planName}
              </p>
            </div>
            {data.planName === "Quarterly Excellence" ? (
              <div className="py-[4px] rounded-lg px-4 text-[#2947D4] font-bold text-[18px] absolute pricingCard8 ml-[190px] md:ml-[240px] -mt-2 bg-[#F5F7FA] text-center">
                Popular
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <p
          className={`pricingCard4 pt-[25px] h-[80px] text-[1.1rem] ${
            data.planName === "Quarterly Excellence"
              ? "text-[#D9DBE9]"
              : "text-[#6F6C90]"
          }`}
        >
          {data.description}
        </p>
        <p
          className={`pricingCard5 pt-[26px] ${
            data.planName === "Quarterly Excellence"
              ? "text-[#fff]"
              : "text-[#170F49]"
          }`}
        >
          {data.price}{" "}
          <span
            className={`pricingCard7 text-xl font-medium  ${
              data.planName === "Quarterly Excellence"
                ? "text-[#fff]"
                : "text-[#6F6C90]"
            } `}
          >
            {data.billingCycle}
          </span>
        </p>
        {data.planName === "Monthly Starter Spark" ? (
          <p
            className={`  text-[#6F6C90] ${
              data.planName === "Quarterly Excellence"
                ? "text-[#D9DBE9]"
                : "text-[#6F6C90]"
            } `}
          >
            {data.discount}
          </p>
        ) : (
          <p
            className={` ${
              data.planName === "Quarterly Excellence"
                ? "text-[#D9DBE9]"
                : "text-[#6F6C90]"
            }`}
          >
            {data.pricePerMonth}
          </p>
        )}
      </div>

      <button
        className={` pricingCard6  py-3 rounded-3xl md:ml-[65px] font-medium    center ${
          data.planName === "Quarterly Excellence"
            ? "bg-[#EFF0F6] text-[#2947D4]"
            : "bg-[#2947D4] text-[#fff]"
        } `}
      >
        Get Started
      </button>
    </div>
  );
};

export default PriceCard;
