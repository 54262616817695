import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Comp from "./Components/Comp";
import ContactUs from "./Components/ContactUs";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsAndCondition from "./Components/TermsAndCondition";
import DeleteAccount from "./Components/DeleteAccount";
import SuccessPage from "./Components/SuccessPage";

function App() {
  return (
    <>
    <Helmet>
    <title>Eduzy</title>
    <meta name="description" content="Elevate your exam readiness with our app: Rich resources, interactive AI Bot and
      targeted practice for optimal exam success" />
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "http://www.eduzy.in",
        "logo": "http://www.eduzy.in/favicon.ico",
      }
      `}
    </script>
  </Helmet>
    <Router>
      <Routes>
        <Route path="/" element={<Comp />} />
        <Route path="/contact" element={<ContactUs/>} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
        <Route path="/termsAndCondition" element={<TermsAndCondition/>} />
        <Route path="/deleteAccount" element={<DeleteAccount/>} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
