import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../assets/logo.png";
const Navbar = (props) => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between min-[1900px]:justify-around items-center h-24  mx-auto px-[30px] md:pl-[50px] md:pr-[100px] text-white navbar w-[100vw] overflow-hidden ">
      <div
        onClick={props.scrollToSection1}
        className="flex flex-row space-x-2 items-center"
      >
        <img src={logo} alt="" className="w-[45px]  " />
        <h1 className=" text-3xl font-bold text-white navbar1 ">Eduzy</h1>
      </div>

      <div className="hidden md:flex flex-row space-x-10  text-[18px] ">
        <button onClick={props.scrollToSection1}>Home</button>
        <button onClick={props.scrollToSection2}>Features</button>

        <button onClick={props.scrollToSection4}>Pricing</button>

        <a href="https://blogs.eduzy.in" target="_blank" rel="noreferrer">
          Blog{" "}
        </a>
      </div>
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        <div
          className={
            nav
              ? "fixed  right-0 space-y-10 pb-3 top-[95px] w-[150px]  border-r border-r-gray-900 bg-[#2947D4] flex flex-col ease-in-out duration-0"
              : "ease-in-out duration-0 fixed left-[-100%]"
          }
        >
          <button onClick={props.scrollToSection1}>Home</button>
          <button onClick={props.scrollToSection2}>Features</button>
          <button onClick={props.scrollToSection4}>Pricing</button>
          <button>
            <a href="https://blogs.eduzy.in">Blog </a>{" "}
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
