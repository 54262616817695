import { useState } from "react";

const ConatactForm = ({ showForm, setForm }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    educationBoard: "", 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: "",
      email: "",
      phone: "",
      educationBoard: "",
    });
    setForm(false);
  };

  return (
    <div
      className={`w-[360px] bg-[#2947D4]  ${
        showForm === false ? "hidden" : "block"
      } absolute -mt-28 z-50 sm:ml-[5vw] -ml-[22px]  `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="26"
        width="22"
        className=" absolute mt-2 ml-4"
        viewBox="0 0 384 512"
        onClick={() => {
          setForm(false);
        }}
      >
        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"    fill="#ffffff" />
      </svg>
      <h2 className="text-center mb-7 text-white text-[1.5rem] pt-5">Education Form</h2>
      <form onSubmit={handleSubmit} className="px-[40px]">
        <div>
          <input
            type="text"
            id="name"
            name="name"
            className="w-[300px] h-[40px] pl-3 focus:outline-none border-b-2 border-[#2947D4] mb-7"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Name*"
          />
        </div>
        <div>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Email*"
            className="w-[300px] h-[40px] pl-3 focus:outline-none border-b-2 border-[#2947D4] mb-7"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="w-[300px] h-[40px] pl-3 focus:outline-none border-b-2 border-[#2947D4] mb-7 "
            required
            placeholder="Contact Number*"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div>
          <select
            id="educationBoard"
            name="educationBoard"
            value={formData.educationBoard}
            required
            onChange={handleChange}
            placeholder="Select your education board"
            className="w-[300px] h-[40px] pl-3 focus:outline-none border-b-2 border-[#2947D4] mb-7 "
          >
            <option value="" disabled hidden>
              Select your education board*
            </option>
            <option value="ICSE">ICSE</option>
            <option value="CBSE">CBSE</option>
            <option value="RBSE">RBSE</option>
            <option value="KSEAB">KSEAB</option>
            <option value="DGE, Tamil Nadu">DGE, Tamil Nadu</option>
            <option value="BSEB">BSEB</option>
            <option value="UPMSP">UPMSP</option>
            <option value="GSEB">GSEB</option>
            <option value="MPBSE">MPBSE</option>
            <option value="JKBOSE">JKBOSE</option>
            <option value="PSEB">PSEB</option>
            <option value="BIEAP">BIEAP</option>
            <option value="BSEAP">BSEAP</option>
            <option value="WBBSE">WBBSE</option>
            <option value="TBSE">TBSE</option>
            <option value="CHSE, Odisha">CHSE, Odisha</option>
            <option value="HPBOSE">HPBOSE</option>
          </select>
        </div>
        <button
          type="submit"
          className="bg-[#2947D4] mx-[30%] mb-7 text-[#F5F7FA] px-5 py-2 border-2 rounded-lg "
        >
          Submit
        </button>
      </form>
    </div>
  );
};
export default ConatactForm;
