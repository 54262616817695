const pricing=[
   {
    billingType:"Billed Monthly",
    planName: "Monthly Starter Spark",
  description: "Join Eduzy monthly and enjoy a delightful learning experience!",
  price: "INR 249",
  billingCycle: "/monthly",
  discount: "Get a discount of 20% on your monthly fee if you sign up now!",
 
   },
   {
    billingType:"Billed Quarterly",
    planName: "Quarterly Excellence",
  description: "Enroll in Eduzy term-wise and navigate exams & homework with seamless ease ",
  price: "INR 549",
  billingCycle: "/quarterly",
pricePerMonth:"charged ar INR 183 /month"
  
   },
   { billingType:"Billed Annually",

    planName: "Annual Achiever",
  description: "Join Eduzy for a full academic year and excel smoothly in tests & assignments.",
  price: "INR 1599",
  billingCycle: "/yearly",
  pricePerMonth:"charged ar INR 133 /month"

   }
]
export default pricing;