import React from "react";
import OurFeature from "../data/OurFeatureData";

const Ourfeature = () => {
  return (
    <div className="pt-[50px] w-[100%] ">
      <p className="text-center text-[3rem]  ourfeature5">Our Features</p>
      <div className="flex flex-row flex-wrap pt-[80px]  justify-around  ">
        {OurFeature.map((data) => {
          return (
            <div className="w-[350px] items-center ">
              <div className=" overflow-hidden  align-middle">
                <img
                  src={data.image}
                  className="w-[280] h-[250px] mx-auto "
                  alt=""
                />
              </div>
              <div className=" my-[80px] ">
                <p className="text-[1.5rem] text-center ourfeature7 mb-3">
                  {data.heading}
                </p>
                <p className="text-justify ourfeature8"> {data.para}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Ourfeature;
