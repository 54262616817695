import React from "react";
import pana from "../assets/pana.png";
import { useState } from "react";
import { Link } from "react-router-dom";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ name, email, message });
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      {" "}
      <div className="flex flex-row bg-[#F5F7FA] w-[100vw] h-[100vh]  md:justify-around ">
        <Link to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="46"
            width="42"
            className=" absolute  mt-[20px] ml-4 md:-ml-10"
            viewBox="0 0 384 512"
          >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </Link>
        <div className="mt-[150px] ml-[10vw]">
          <p className="mb-[70px] text-[30px] font-medium text-blue-600">
            Contact Us
          </p>

          <form id="contact-form" onSubmit={handleSubmit} className=" ">
            <div className="form-group">
              <input
                type="text"
                className="form-control w-[320px] md:w-[350px]  pl-[10px] pt-2 focus:outline-none border-b-2 border-black bg-white mb-7"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                placeholder="Name*"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control w-[320px] md:w-[350px]  pl-[10px] pt-2 focus:outline-none border-b-2 border-black mb-7 bg-white"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email*"
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control w-[320px] md:w-[350px] pl-[10px] pt-2 focus:outline-none border-b-2 border-black  bg-white mb-7"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="5"
                required
                placeholder="Message*"
              ></textarea>
            </div>
            <button
              type="submit"
              className=" bg-blue-600 py-2 px-[30px] font-serif rounded-lg text-white"
            >
              Submit
            </button>
          </form>
        </div>
        <img
          src={pana}
          className="w-[500px] h-[500px] mt-[150px] hidden lg:block "
          alt=""
        />
      </div>
    </>
  );
};

export default ContactUs;
