import React, { useRef } from "react";
import {
  Heropage,
  Ourfeature,
  Pricing,
  Faq,
  Footer,
  Navbar,
} from "./Allimports";


const Comp = () => {
  const home = useRef(null);

  const offer = useRef(null);
  const pricing = useRef(null);
  const faq = useRef(null);
  const getInTouch = useRef(null);

  const scrollToSection1 = () => {
    home.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSection2 = () => {
    offer.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection4 = () => {
    console.log("testimonial");
    pricing.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection6 = () => {
    getInTouch.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar
        scrollToSection1={scrollToSection1}
        scrollToSection2={scrollToSection2}
        scrollToSection4={scrollToSection4}
        scrollToSection6={scrollToSection6}
      />
      <div ref={home}>
        {" "}
        <Heropage />{" "}
      </div>

      <div ref={offer}>
        {" "}
        <Ourfeature />{" "}
      </div>

      <div ref={pricing}>
        {" "}
        <Pricing />{" "}
      </div>

      <div ref={faq}>
        {" "}
        <Faq />{" "}
      </div>

      <Footer />
    </div>
  );
};

export default Comp;
