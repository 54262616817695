import { useState } from "react";
import image1 from "../assets/imag1.png";
import image2 from "../assets/imag2.png";
import image3 from "../assets/imag3.png";
import image4 from "../assets/imag4.png";
import image5 from "../assets/imag5.png";
import image6 from "../assets/imag6.png";
import image7 from "../assets/imag7.png";
import image8 from "../assets/imag8.png";
import image9 from "../assets/imag9.png";
import image10 from "../assets/imag10.png";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

export default function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="relative w-full h-screen flex items-center justify-center bg-black select-none">
      {/* Image Display */}
      <div
        className="relative w-full h-full overflow-hidden flex items-center justify-center"
        onContextMenu={(e) => {
          e.preventDefault();
          nextSlide();
        }}
        onClick={prevSlide}
      >
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full h-full object-cover max-w-full max-h-full"
        />
      </div>

      {/* Next and Previous Buttons */}
      <div className="absolute bottom-5 w-full flex justify-between px-5 md:px-10">
        <button
          className="bg-gray-800 text-white px-4 md:px-6 py-2 md:py-3 rounded-md shadow-lg"
          onClick={prevSlide}
        >
          Previous
        </button>
        <button
          className="bg-gray-800 text-white px-4 md:px-6 py-2 md:py-3 rounded-md shadow-lg"
          onClick={nextSlide}
        >
          Next
        </button>
      </div>
    </div>
  );
}