import React from "react";
import pricing from "../data/pricingData";

const PricingPage = () => {
  return (
    <div id="pricing" className="py-12 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="space-y-6 ">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-normal text-[#0040FF]">
            Pricing
          </h1>
          <p className="text-base sm:text-lg md:text-xl font-normal text-gray-800">
            Register with Eduzy for a{" "}
            <span className="font-bold text-black">10-day free trial</span> & experience how we make your studies, assignments & exam preparation so much easier!
          </p>
        </div>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-12">
          {pricing.map((plan, index) => (
            <div
              key={index}
              className="bg-[#0040FF] text-white rounded-xl p-6 sm:p-8 flex flex-col h-full shadow-lg"
            >
              <div className="flex-grow space-y-6">
                {/* Plan Name and Billing Type */}
                <div className="space-y-2">
                  <h2 className="text-xl sm:text-2xl font-normal">
                    {plan.planName}
                  </h2>
                  <p className="text-sm sm:text-base font-normal">
                    {plan.billingType}
                  </p>
                </div>

                {/* Description */}
                {!Array.isArray(plan.description) && (
                  <p className="text-sm sm:text-base font-normal">
                    {plan.description}
                  </p>
                )}

                {/* Price Information */}
                {plan.price && (
                  <div className="space-y-1">
                    <p className="text-xl sm:text-2xl font-normal">
                      {plan.price} {plan.billingCycle}
                    </p>
                    {plan.pricePerMonth && (
                      <p className="text-sm sm:text-base font-normal">
                        charged at {plan.pricePerMonth}
                      </p>
                    )}
                  </div>
                )}

                {/* Discount */}
                {plan.discount && (
                  <p className="text-sm sm:text-base font-normal text-gray-300">
                    {plan.discount}
                  </p>
                )}

                {/* Features List */}
                {Array.isArray(plan.description) && (
                  <ul className="space-y-3">
                    {plan.description.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-start">
                        <span className="mr-2 text-lg">•</span>
                        <span className="text-sm sm:text-base font-normal">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Action Button */}
              <button
                className="w-full mt-6 py-3 bg-white text-[#0040FF] hover:bg-blue-100 rounded-lg text-sm sm:text-base font-medium transition duration-300"
              >
                {index === 3 ? "Select Plan" : "Select Plan"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
