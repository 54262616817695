

const FaqQuesData = [
    {
        ques:"Can I use Eduzy for all my subjects?",
        ans:`Yes, you can use our mobile application for self-study for all your subjects. `
    },
    {
        ques:"When will Eduzy be available for ICSE and State Boards? ",
        ans:`Currently, We provide content only for CBSE (Central Board of Secondary Education) board. We are working on launching content and support for ICSE (Indian School Certificate Examinations) and State Boards in the future.  `,
       
    },
    {
        ques:"Can I try Eduzy first, to make sure it's right for me?",
        ans:`Yes, you can take advantage of the 14-days trial period to experience learning with Eduzy, before you decide to make a purchase. You do NOT need to provide any card information or payment information during the trial period. 

        Once the trial period is complete, you can complete your payment on the app directly and continue learning with Eduzy.
         `
    }


];
export default FaqQuesData;