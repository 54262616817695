import { data1, data2, data3, data4 } from "../data/TerrnsAndConditionData";
const TermsAndCondition = () => {
  return (
    <div className="mx-[40px] mt-[40px] ">
      <h2 className="text-[#2947D4] text-xl md:text-2xl font-medium mb-3 text-justify">
        Terms and Conditions for Eduzy Mobile Application & Website
      </h2>
      <p className="text-justify text-[0.85rem] md:text-lg ">
        Welcome to Eduzy, an innovative educational mobile application ("App")
        developed and owned by Itonic Labs Pvt Ltd. ("Company," "we," "us," or
        "our"). These Terms and Conditions ("Terms") govern your access to and
        use of the Eduzy App and website{" "}
        <a href="www.eduzy.in" className="text-blue-700">
          (www.eduzy.in)
        </a>
        . By accessing or using the Eduzy App/website, you agree to be bound by
        these Terms. If you do not agree to these Terms, please do not use the
        Eduzy App/website.
      </p>

      <div>
        <p className="text-[#2947D4] text-xl md:text-2xl font-medium mb-3 text-justify mt-6">
          {data1.title}
        </p>
        <p className="text-[0.85rem] md:text-lg">{data1.description1}</p>
        <ul className="list-disc mb-5 text-[0.85rem] md:text-lg">
          <p className="my-3 mt-8 text-[0.85rem] md:text-lg">You must not:</p>
          {data1.description2.map((desc, idx) => (
            <li
              className="ml-8 text-justify text-[0.85rem] md:text-lg"
              key={idx}
            >
              {desc}
            </li>
          ))}
        </ul>
        <p className="text-justify text-[0.85rem] md:text-lg">
          {data1.description3}
        </p>
        <p className="text-justify text-[0.85rem] md:text-lg">
          {data1.description6}
        </p>
        <ul className="list-disc text-justify mb-4 text-[0.85rem] md:text-lg">
          <p className="my-3 mt-8 text-justify text-[0.85rem] md:text-lg">
            You warrant and represent that:
          </p>
          {data1.description4.map((desc, idx) => (
            <li
              className="ml-8 text-justify text-[0.85rem] md:text-lg"
              key={idx}
            >
              {desc}
            </li>
          ))}
        </ul>
        <p className="text-justify text-[0.85rem] md:text-lg">
          {data1.description5}
        </p>
      </div>
      {data2.map((data, idx) => (
        <div className="mt-4 text-justify">
          <p className="text-[#2947D4] text-xl md:text-2xl font-medium mb-3 text-justify mt-6">
            {data.title}
          </p>
          {data.description.map((desc, idx) => (
            <p className="text-justify text-[0.85rem] md:text-lg">{desc}</p>
          ))}
        </div>
      ))}

      <p className="text-[#2947D4] text-xl md:text-2xl font-medium mb-3 text-justify mt-6">
        {data3.title}
      </p>
      <p className="text-justify text-[0.85rem] md:text-lg">
        {data3.description1}
      </p>
      {data3.description2.map((desc, idx) => (
        <li className="ml-8 text-justify text-[0.85rem] md:text-lg" key={idx}>
          {desc}
        </li>
      ))}
      <br />
      {data3.description3.map((desc, idx) => (
        <p className="mb-4 text-justify text-[0.85rem] md:text-lg">{desc}</p>
      ))}
      {data3.description4.map((desc, idx) => (
        <li className="ml-8 text-justify text-[0.85rem] md:text-lg" key={idx}>
          {desc}
        </li>
      ))}
      <br />
      {data3.description5.map((desc, idx) => (
        <p className="mb-4 text-justify text-[0.85rem] md:text-lg">{desc}</p>
      ))}
      {data3.description6.map((desc, idx) => (
        <li className="ml-8 text-justify text-[0.85rem] md:text-lg" key={idx}>
          {desc}
        </li>
      ))}
      <br />
      <p className="text-justify text-[0.85rem] md:text-lg">
        {data3.description7}
      </p>

      {data4.map((data, idx) => (
        <div>
          <p className="text-[#2947D4] text-xl md:text-2xl font-medium mb-3 text-justify mt-6">
            {data.title}
          </p>
          {data.description.map((desc, idx) => (
            <p className="mb-2 text-justify text-[0.85rem] md:text-lg">
              {desc}
            </p>
          ))}
        </div>
      ))}

      <p className="text-[#2947D4] text-xl md:text-2xl font-medium mb-3 text-justify mt-6">
        {" "}
        20. Contact Information
      </p>
      <p className="text-justify text-[0.85rem] md:text-lg">
        20.1. If you have any questions about these Terms or the Eduzy App/
        website, please contact us at{" "}
        <a href="mailto:support@itoniclabs.in" className="text-blue-600">
          support@itoniclabs.in
        </a>
      </p>
      <p className="mb-4 text-justify text-[0.85rem] md:text-lg">
        By using the Eduzy App/website, you acknowledge that you have read,
        understood, and agree to be bound by these Terms and our Privacy Policy.
        If you do not agree to these Terms, please do not access or use the
        Eduzy App/website.
      </p>
    </div>
  );
};
export default TermsAndCondition;
