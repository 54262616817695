import PriceCard from "./PriceCard";
import pricingdata from "../data/pricingData";
const Pricing = () => {
  return (
    <div className="pt-[50px] bg-[#F5F7FA] pb-[120px] w-[100%]">
      <div className="text-center">
        <p className="pricing2 pb-[27px]">Pricing</p>
        <p className="pricing3 text-[1.1rem] sm:text-[1.2rem] text-justify sm:px-[7vw] mt-[6px]">
          Register with Eduzy for a 10-day free trial & experience how we make
          your studies, assignments and exam preparation so much easier!
        </p>
      </div>
      <div
        className={`flex flex-row flex-wrap md:space-x-5  justify-around pt-[45px]`}
      >
        {pricingdata.map((data, index) => {
          return <PriceCard data={data} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Pricing;
