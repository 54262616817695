import React from "react";
import image from "../assets/header.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-white py-6 sm:py-8 md:py-10 lg:py-12 mt-10 sm:mt-14">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center gap-6 md:gap-4 lg:gap-2 mt-16">
        {/* Left Content */}
        <div className="w-full  md:w-1/2 text-center md:text-left space-y-4 sm:space-y-6 md:space-y-8 lg:space-y-10 mb-6 md:mb-0">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-900">
            <span className="block">Personalized Learning for</span>
            <span className="block mt-2 sm:mt-4">Every Student</span>
          </h1>

          <p className="text-base sm:text-lg md:text-xl text-black-900 leading-relaxed max-w-xl mx-auto md:mx-0">
            <span className="block">
              Are you a student wanting to transform the way you{" "}
              <br className="hidden sm:inline" /> study?
            </span>
            <span className="block mt-3 sm:mt-5">
              Or a school seeking tools to empower teachers and{" "}
              <br className="hidden sm:inline" />
              inspire students?
            </span>
          </p>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 md:gap-5">
            <Link to="" className="w-full sm:w-auto">
              <button className="w-full sm:w-auto min-w-[10rem] px-4 sm:px-6 md:px-8 py-2.5 sm:py-3 bg-blue-600 text-white text-sm sm:text-base md:text-lg font-medium rounded-2xl hover:bg-blue-700 shadow-md transition">
                Get started for free <br /> (for students)
              </button>
            </Link>
            <Link to="" className="w-full sm:w-auto">
              <button className="w-full sm:w-auto min-w-[10rem] px-4 sm:px-6 md:px-8 py-2.5 sm:py-3 bg-blue-600 text-white text-sm sm:text-base md:text-lg font-medium rounded-2xl hover:bg-blue-700 shadow-md transition">
                Get A Demo <br /> (for schools)
              </button>
            </Link>
          </div>
        </div>

        {/* Right Image - Improved Responsive Sizing */}
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <img
            src={image || "/placeholder.svg"}
            alt="Student studying"
            className="rounded-full w-11/12 sm:w-3/4 md:w-full max-w-md lg:max-w-lg aspect-square shadow-xl object-cover"
            loading="lazy"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;