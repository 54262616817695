import { data } from "../data/PrivacyPolicyData";

const PrivacyPolicy = () => {
  return (
    <div className="mx-[40px] mt-[40px]">
      {data.map((data, index) => {
        return (
          <div className="mb-10">
            <h2 className="text-[#2947D4] text-2xl font-medium mb-3 text-justify">
              {data.title}
            </h2>
            {/* <h3 className="text-justify">{data.description}</h3> */}
            {data.description.map((paragraph, idx) => (
              <p
                className="mb-3 text-justify text-[0.85rem] md:text-lg"
                key={idx}
              >
                {paragraph}
              </p>
            ))}
            {index === 3 ? (
              <ul className="list-disc">
                <p className="my-3 mt-8 text-[0.85rem] md:text-lg">
                  We may disclose Information in the following situations:
                </p>
                {data.description2.map((desc, idx) => (
                  <li className="ml-8 text-[0.85rem] md:text-lg" key={idx}>
                    {desc}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        );
      })}
      <p className="mb-4 text-[0.85rem] md:text-lg">
        If you have further questions or need additional details regarding our
        Privacy Policy, feel free to reach out to us at{" "}
        <a href="mailto:support@itoniclabs.in" className="text-blue-600">
          support@itoniclabs.in
        </a>
        .
      </p>
    </div>
  );
};
export default PrivacyPolicy;
